<template>
    <div class="px-3 py-2" v-if="item">
        <b-overlay :show="loading" rounded="sm">
            
            <ContactInformation :user_uuid="user_uuid" :functionbox_uuid="functionbox_uuid" :identifier="item.email"></ContactInformation>
            
            <dl class="mt-2 clearfix">
                <h3><i class="fal fa-bell">&nbsp;&nbsp;</i>{{ $t("NOTIFICATION") }}</h3>
            </dl>

            <hr class="pb-0 mb-0 mt-2" />
            
            <dl class="mt-2 clearfix">
                <dt>{{ $t("STATUS") }}</dt>
                <dd>
                    <MessageStatus :status="item.status" show-text></MessageStatus>
                </dd>
                <dt>{{ $t("TYPE") }}</dt>
                <dd>{{ item.notification_type }}</dd>
                <dt>{{ $t("VIEWED") }}</dt>
                <dd>{{ item.viewed ? $t("YES") : $t("NO") }}</dd>
            </dl>
            
            <hr class="pb-0 mb-0 mt-2" />

            <div v-if="item.id != undefined" class="pb-0 mb-0 mt-3">
                <dt>{{ item.subject }}</dt>
                <dd v-if="item.content" class="mt-2">
                    {{item.content}}
                </dd>
                <small class="text-muted">{{ formattedDate }}</small>
            </div>
            
            <b-button variant="secondary" size="sm" class="no-border mt-4" @click="viewMessage">
                {{ $t("GOTO_MESSAGE") }}
            </b-button>
            
            <b-button variant="danger" class="btn-fill mt-4" size="sm" @click="confirmRemove">
                {{ $t("REMOVE") }}
            </b-button>

        </b-overlay>
    </div>
</template>

<script>
import ContactInformation from "./ContactInformation";
import MessageStatus from "./MessageStatus";

export default {
    components: {
        ContactInformation,
        MessageStatus,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        functionbox_uuid: {
            type: String,
            required: true,
        },
        user_uuid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        async init() {
            setTimeout(() => {
                this.$store.dispatch("user/fetchUser");
            }, 500);
        },
        viewMessage() {
            this.$emit("gotoMessage", this.item);
        },
        async confirmRemove() {
            const confirmed = await this.$bvModal.msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
                size: "sm",
                buttonSize: "sm",
                okVariant: "primary",
                okTitle: this.$t("YES"),
                cancelTitle: this.$t("NO"),
                bodyClass: "messagebox",
                footerClass: "p-2 text-center messagebox",
                hideHeaderClose: false,
                centered: true,
            });
    
            if (confirmed) {
                this.remove();
            }
        },
        async remove() {
            try {
                this.loading = true;
                await this.$http.post(this.user.hostname + "/notification/remove", {
                    id: this.item.id,
                    user_uuid: this.item.user_uuid,
                });
                this.$emit("removeNotification");
                this.$noty.info(this.$t("REMOVED"));
            } catch (error) {
                if (error.response != undefined) {
                    this.$noty.error(this.$t(error.response.data));
                }
            } finally {
                this.loading = false;
            }
        },
        FormatDateTime(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString(undefined, options);
        },
        timeAgo(date) {
            const now = new Date();
            const createdDate = new Date(date);
            const diffMinutes = Math.floor((now - createdDate) / 1000 / 60);
            const diffHours = Math.floor(diffMinutes / 60);

            if (diffMinutes < 60) {
                return `${diffMinutes} ${this.$t("MINUTES_AGO")}`;
            } else if (diffHours < 24) {
                const hours = diffHours;
                const minutes = diffMinutes % 60;
                return `${hours} ${this.$t("HOURS")} ${minutes} ${this.$t("MINUTES_AGO")}`;
            } else {
                return this.FormatDateTime(date);
            }
        }
    },
    computed: {
        formattedDate() {
            return this.timeAgo(this.item.created_date);
        }
    },
    mounted() {
        this.init();
    },
};
</script>

<style>
.text-muted {
    color: rgba(0, 0, 0, 0.5);
}
</style>
