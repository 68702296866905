var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item
    ? _c(
        "div",
        { staticClass: "px-3 py-2" },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading, rounded: "sm" } },
            [
              _c("ContactInformation", {
                attrs: {
                  user_uuid: _vm.user_uuid,
                  functionbox_uuid: _vm.functionbox_uuid,
                  identifier: _vm.item.email,
                },
              }),
              _c("dl", { staticClass: "mt-2 clearfix" }, [
                _c("h3", [
                  _c("i", { staticClass: "fal fa-bell" }, [_vm._v("  ")]),
                  _vm._v(_vm._s(_vm.$t("NOTIFICATION"))),
                ]),
              ]),
              _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
              _c("dl", { staticClass: "mt-2 clearfix" }, [
                _c("dt", [_vm._v(_vm._s(_vm.$t("STATUS")))]),
                _c(
                  "dd",
                  [
                    _c("MessageStatus", {
                      attrs: { status: _vm.item.status, "show-text": "" },
                    }),
                  ],
                  1
                ),
                _c("dt", [_vm._v(_vm._s(_vm.$t("TYPE")))]),
                _c("dd", [_vm._v(_vm._s(_vm.item.notification_type))]),
                _c("dt", [_vm._v(_vm._s(_vm.$t("VIEWED")))]),
                _c("dd", [
                  _vm._v(
                    _vm._s(_vm.item.viewed ? _vm.$t("YES") : _vm.$t("NO"))
                  ),
                ]),
              ]),
              _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
              _vm.item.id != undefined
                ? _c("div", { staticClass: "pb-0 mb-0 mt-3" }, [
                    _c("dt", [_vm._v(_vm._s(_vm.item.subject))]),
                    _vm.item.content
                      ? _c("dd", { staticClass: "mt-2" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.item.content) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.formattedDate)),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "b-button",
                {
                  staticClass: "no-border mt-4",
                  attrs: { variant: "secondary", size: "sm" },
                  on: { click: _vm.viewMessage },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("GOTO_MESSAGE")) +
                      "\n        "
                  ),
                ]
              ),
              _c(
                "b-button",
                {
                  staticClass: "btn-fill mt-4",
                  attrs: { variant: "danger", size: "sm" },
                  on: { click: _vm.confirmRemove },
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.$t("REMOVE")) + "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }