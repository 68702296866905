var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c("InboxFilter", {
        attrs: { amount: 2, filterName: "VIEWED" },
        model: {
          value: _vm.filter_viewed,
          callback: function ($$v) {
            _vm.filter_viewed = $$v
          },
          expression: "filter_viewed",
        },
      }),
      _vm.showFilterClearAll
        ? _c(
            "b-button",
            {
              staticClass: "no-border",
              attrs: { size: "sm" },
              on: { click: _vm.clearFilterAll },
            },
            [_vm._v(_vm._s(_vm.$t("FILTER.CLEAR")))]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("b-table", {
            attrs: {
              small: "",
              bordered: "",
              items: _vm.items,
              hover: "",
              fields: _vm.fields,
              busy: _vm.loading,
              "tbody-tr-class": _vm.rowClass,
            },
            on: { "row-clicked": _vm.clickedRow },
            scopedSlots: _vm._u([
              {
                key: "cell(created_date)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.FormatDateTime(data.item.created_date)) +
                        "\n            "
                    ),
                  ]
                },
              },
              {
                key: "cell(subject)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(data.item.subject) +
                        "\n            "
                    ),
                  ]
                },
              },
              {
                key: "cell(content)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(data.item.content) +
                        "\n            "
                    ),
                  ]
                },
              },
              {
                key: "cell(viewed)",
                fn: function (data) {
                  return [
                    _c("img", {
                      staticClass: "icon mr-1",
                      attrs: {
                        src: _vm.messageIcon(data.item.notification_type),
                        alt: _vm.$t("MESSAGES.TYPE." + "notification"),
                      },
                    }),
                    _c("MessageStatus", {
                      attrs: { status: data.item.status },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("b-pagination", {
            staticClass: "mt-2",
            attrs: {
              "total-rows": _vm.totalItems,
              "per-page": _vm.limit,
              align: "center",
              pills: "",
            },
            on: { input: _vm.getNotifications },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
          _c(
            "b-sidebar",
            {
              attrs: {
                right: "",
                "no-slide": "",
                shadow: "",
                lazy: "",
                backdrop: "",
              },
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _vm.showItem
                ? _c("NotificationInformation", {
                    key: _vm.showItem.id,
                    attrs: {
                      item: _vm.showItem,
                      functionbox_uuid: _vm.functionbox_uuid,
                      user_uuid: _vm.user_uuid,
                    },
                    on: {
                      gotoMessage: _vm.gotoMessage,
                      removeNotification: _vm.handleRemoveNotification,
                      viewedNotification: _vm.handleViewedNotification,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }