<template>
    <section class="mt-2">
        <InboxFilter :amount="2" filterName="VIEWED" v-model="filter_viewed"></InboxFilter>
  
        <b-button class="no-border" size="sm" v-if="showFilterClearAll" @click="clearFilterAll">{{ $t("FILTER.CLEAR") }}</b-button>
    
        <div class="mt-2">
            <b-table
                small
                bordered
                :items="items"
                hover
                :fields="fields"
                @row-clicked="clickedRow"
                :busy="loading"
                :tbody-tr-class="rowClass"
            >
                <template v-slot:cell(created_date)="data">
                    {{ FormatDateTime(data.item.created_date) }}
                </template>
                <template v-slot:cell(subject)="data">
                    {{ data.item.subject }}
                </template>
                <template v-slot:cell(content)="data">
                    {{ data.item.content }}
                </template>
                <template v-slot:cell(viewed)="data">
                    <img class="icon mr-1" :src="messageIcon(data.item.notification_type)" :alt="$t('MESSAGES.TYPE.' + 'notification')"  />
                    <MessageStatus :status="data.item.status"></MessageStatus>
                </template>
            </b-table>
    
            <b-pagination
                class="mt-2"
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="limit"
                align="center"
                @input="getNotifications"
                pills
            ></b-pagination>
    
            <b-sidebar v-model="show" right no-slide shadow lazy backdrop>
                <NotificationInformation
                    v-if="showItem"
                    :key="showItem.id"
                    :item="showItem"
                    :functionbox_uuid="functionbox_uuid"
                    :user_uuid="user_uuid"
                    @gotoMessage="gotoMessage"
                    @removeNotification="handleRemoveNotification"
                    @viewedNotification="handleViewedNotification"
                ></NotificationInformation>
            </b-sidebar>
        </div>
    </section>
</template>
  
<script>
import InboxFilter from "@/components/Input/Filter/InboxFilter";
import NotificationInformation from "@/components/View/NotificationInformation";
import MessageStatus from "@/components/View/MessageStatus";

export default {
    components: { InboxFilter, NotificationInformation, MessageStatus },
    props: ["functionbox_uuid", "user_uuid", "searchText", "limit"],
    data() {
        return {
            loading: false,
            currentPage: 1,
            redrawIndex: 0,
            items: [],
            totalItems: 0,
            filter_viewed: ["0", "1"],
            show: false,
            showItem: null,
            showIndex: 0,
        };
    },
    methods: {
        clearFilterAll() {
            this.filter_viewed = [];
            this.getNotifications();
        },
        rowClass(item, type) {
            if (item && type === "row") {
                let returnClass = "";
                if (item.viewed === 0) {
                    returnClass += " not-viewed";
                }
                return returnClass;
            }
            return "";
        },
        async clickedRow(data, index) {
            this.showItem = data;
            this.showIndex = index;
            this.show = true;
    
            if (data.viewed === 0) {
                try {
                    await this.$http.post(this.user.hostname + "/inbox/notification/viewed", {
                        functionbox_uuid: this.functionbox_uuid,
                        user_uuid: this.user_uuid,
                        notificationId: data.id,
                    });
                    data.viewed = 1;
                } catch {
                    this.$noty.error(this.$t("ERROR.COULD_NOT_MARK_AS_VIEWED"));
                }
            }
        },
        getNotifications() {
            let self = this;
            this.loading = true;
            this.$http.post(this.user.hostname + "/notification/list", {
                user_uuid: this.user_uuid,
                functionbox_uuid: this.functionbox_uuid,
                search_text: this.searchText,
                page: this.currentPage,
                filter_viewed: this.filter_viewed,
                limit: parseInt(this.limit),
            })
            .then((response) => {
                self.redrawIndex += 1;
                self.loading = false;
                self.items = response.data.items;
                self.totalItems = response.data.count;
            })
            .catch(() => {
                self.loading = false;
                self.$noty.error(self.$t("ERROR.COULD_NOT_FETCH_DATA"));
            });
        },
        gotoMessage(notificationId) {
            this.show = false;
            this.$emit("viewingNotification", notificationId);
        },
        handleRemoveNotification() {
            this.show = false;
            this.getNotifications();
        },
        handleViewedNotification() {
            this.getNotifications();
        },
        FormatDateTime(date) {
            return new Date(date).toLocaleString();
        },
        messageIcon(type) {
            if(type == 'FAX')
            {
                return "/img/message_type/fax-message.png";
            }
            if(type == 'SDK')
            {
                return "/img/message_type/sdk-message.png";
            }
            return "/img/message_type/notification.png";
        },
    },
    computed: {
        fields() {
            return [
                { key: "viewed", label: "", class: "text-break w-icon clickable hidden-mobile", sortable: false },
                { key: "subject", label: this.$t("SUBJECT"), class: "text-break clickable", sortable: false },
                { key: "content", label: this.$t("CONTENT"), class: "text-break clickable", sortable: false },
                { key: "created_date", label: this.$t("CREATED"), class: "text-break w-date clickable", sortable: false },
            ];
        },
        showFilterClearAll() {
            if (this.filter_viewed.length > 0) {
                return true;
            }
            return false;
        },
    },
    watch: {
        filter_viewed() {
            this.getNotifications();
        },
        currentPage() {
            this.getNotifications();
        },
        limit() {
            this.getNotifications();
        },
    },
    mounted() {
        this.getNotifications();
    },
};
</script>

<style></style>
